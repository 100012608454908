import React from 'react'
import useLocale from '@logdoc/l10n';
import LayoutDefault from "../layouts";
import * as classes from '../sass/style.module.scss'
import { L10nProvider } from '@logdoc/l10n';
const Ac = () => {
    return (
        <L10nProvider>
            <Access />
        </L10nProvider>
    )
}
export const Access = () => {
    const t = useLocale()
    return (
        <LayoutDefault>
            <section className={classes.sec}>
                <div className={classes.secBreadcrumbs}>
                    <span>{t('access.breadcrumbs.1')}</span>
                    <span>/</span>
                    <span>{t('access.breadcrumbs.2')}</span>
                </div>
                <h2 className={classes.secTitle}>
                    {t('access.title')}
                </h2>
                <div className={classes.secDescription}>
                    <p>
                        {t('access.description.p1')} <strong>{t('access.description.p2')}</strong>.
                    </p>
                    <p>
                        <strong>{t('access.description.p3')}</strong> - {t('access.description.p4')}<br/>
                        {t('access.description.p5')}
                        <ul>
                            <li><span className={classes.textOrange}>{t('access.description.p6')}</span>{t('access.description.p7')}</li>
                            <li><span className={classes.textOrange}>{t('access.description.p8')}</span>{t('access.description.p9')}</li>
                            <li><span className={classes.textOrange}>{t('access.description.p10')}</span>{t('access.description.p11')}</li>
                            <li><span className={classes.textOrange}>{t('access.description.p12')}</span>{t('access.description.p13')}</li>
                            <li><span className={classes.textOrange}>{t('access.description.p14')}</span>{t('access.description.p15')}</li>
                        </ul>
                        {t('access.description.p16')}<br/>
                    </p>
                    <p>
                        <strong>{t('access.description.p17')}</strong>{t('access.description.p18')}
                    </p>
                    <p>
                        <strong>{t('access.description.p19')}</strong>{t('access.description.p20')}
                    </p>
                    <p>
                        {t('access.description.p21')} <br/>
                        {t('access.description.p22')}
                    </p>
                </div>
                <h2 className={classes.secTitle}>
                    {t('access.description.p23')}
                </h2>
                <div className={classes.secDescription}>
                    <p>
                        {t('access.description.p24')} <a href="/src/pages/versions">{t('access.description.p25')} Pro</a> {t('access.description.p26')}
                    </p>
                    <p>
                        {t('access.description.p27')}
                        <ul>
                            <li>{t('access.description.p28')}</li>
                            <li>{t('access.description.p29')}</li>
                            <li>{t('access.description.p30')}</li>
                            <li>{t('access.description.p31')}</li>
                            <li>{t('access.description.p32')}</li>
                        </ul>
                    </p>
                </div>
            </section>
        </LayoutDefault>
    );
};

export default Ac;
